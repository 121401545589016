<template>
  <a-row class="content-fix" type="flex">
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab == 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />{{ $t("ingradient") }}
        </button>
        <button
          v-if="form.type == 1"
          class="tab-btn"
          :class="{ active: tab == 'socials' }"
          @click="tab = 'socials'"
        >
          <a-icon type="link" />{{ $t('SocialNetwork') }}
        </button>
      </a-row>
      <!-- lang tabs -->
      <div v-show="tab == 'content'">
        <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
          <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <!-- <a-button v-if="langTab == 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab == "uz" ? "кирилицу" : "латиницу" }}
              </a-button> -->
            </a-row>
          </a-col>
        </a-row>
        <!-- title -->
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">Image: </a-col>
          <a-col :span="12" class="sm-mb">
            <div class="form_image">
              <div class="deputy_image">
                <img :src="avatar" />
              </div>
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload
                Image</a-button
              >
              <span v-if="!avatar" style="padding-left: 8px"
                >Картинка не выбрана</span
              >
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFile"
              />
            </div>
          </a-col>
        </a-row>
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="form_title">
            <label>Ф.И.О</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.name[langTab]"
              class="form_title-input"
              :placeholder="$t('FormFirstNamePlace')"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Должность</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.work_place[langTab]"
              class="form_title-input"
              placeholder="Введите должность"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Xodim turi</label>
          </a-col>
          <a-col :span="4" class="sm-mb">
            <a-select
              v-model="form.type"
              :placeholder="$t('Select')"
              style="width: 100%; padding-right: 8px"
            >
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name && item.name[$i18n.locale] }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <div v-if="form.type == 1">
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>Год рождения</label>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                v-model="form.birth_date"
                type="date"
                class="inputNumber"
                placeholder="Введите год рождения"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>Место рождения</label>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-select
                v-model="form.birth_region_id"
                :placeholder="$t('Select')"
                style="width: 100%; padding-right: 8px"
                @change="onChangeRegionBirth"
              >
                <a-select-option
                  v-for="item in regionList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-select
                v-model="form.birth_district_id"
                :placeholder="$t('Select')"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in districtListBirth"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                style="margin-left: 10px"
                v-model="form.birth_address[langTab]"
                placeholder="Адрес"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>Адрес проживания</label>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-select
                v-model="form.living_region_id"
                :placeholder="$t('Select')"
                style="width: 100%; padding-right: 8px"
                @change="onChangeRegionLiving"
              >
                <a-select-option
                  v-for="item in regionList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <!-- <a-col :span="4" class="sm-mb">
            <a-select
              v-model="form.living_district_id"
              :placeholder="$t('Select')"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in districtListLiving"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-col> -->
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>Национальность</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.nationality[langTab]"
                placeholder="Национальность"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("Degree") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.degree[langTab]"
                :placeholder="$t('Degree')"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("Specialty") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.profession[langTab]"
                :placeholder="$t('Specialty')"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>Mukofotlari</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.awards[langTab]"
                placeholder="Mukofotlari"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>Email</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form.email" :placeholder="$t('EnterEmail')" />
            </a-col>
          </a-row>
        </div>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title"> Позиция: </a-col>
          <a-col :span="4" class="sm-mb">
            <a-input
              v-model="form.position"
              type="number"
              name="position"
              placeholder="Введите позицию"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 15px">
          <a-col :span="4" class="form_title"> {{ $t("Status") }}: </a-col>
          <a-switch v-model="form.status" />
        </a-row>
        <!-- editor -->
        <a-row v-if="form.type == 1" style="margin-top: 25px" type="flex">
          <a-col :span="4" class="form_title"> Биография: </a-col>
          <a-col v-show="langTab == 'oz'" :span="18">
            <editor
              :value="form.biography.oz"
              :initial-value="form.biography.oz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip:'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.oz = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'uz'" :span="18">
            <editor
              :value="form.biography.uz"
              :initial-value="form.biography.uz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip:'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.uz = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'ru'" :span="18">
            <editor
              :value="form.biography.ru"
              :initial-value="form.biography.ru"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip:'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.ru = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'en'" :span="18">
            <editor
              :value="form.biography.en"
              :initial-value="form.biography.en"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip:'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.en = editorChangeHandler(arguments[0])"
            />
          </a-col>
        </a-row>
        <a-row
          class="form-buttons__action"
          style="margin-top: 20px"
          type="flex"
        >
          <a-col :span="4" class="form_title" />
          <a-col>
            <a-button :disabled="pending" @click="$router.go(-1)">
              <a-icon type="close" />{{ $t("Cancel") }}
            </a-button>

            <a-button
              :loading="pending"
              style="margin-left: 10px"
              type="primary"
              @click="submitStaffs"
            >
              <a-icon type="check" />
              Добавить
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div v-show="tab == 'socials'">
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Telegram</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.telegram" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Instagram</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.instagram" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Facebook</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.facebook" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Twitter</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.twitter" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
      </div>
    </a-col>
    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import spoiler from "@/mixins/spoiler.js";
const newsDefaultFields = () => ({
  name: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  nationality: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  awards: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  socials: {
    telegram: "",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  biography: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  work_place: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  degree: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  profession: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  birth_address: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  email: "",
  birth_date: "",
  birth_region_id: "",
  birth_district_id: "",
  living_region_id: "",
  // living_district_id: "",
  status: true,
  avatar: null,
  position: null,
  type: 1,
});
export default {
  name: "Staffs",
  components: {
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler"),
    editor: () => import("@tinymce/tinymce-vue"),
  },
  mixins: [spoiler],
  data() {
    return {
      langTab: $langPrefix,
      form: { ...newsDefaultFields() },
      pending: false,
      loading: false,
      emptyTitle: false,
      modalVisible: false,
      avatar: null,
      regionList: [],
      districtListBirth: [],
      districtListLiving: [],
      tab: "content",
      typeList: [
        {
          value: 1,
          name: {
            uz: "Senator",
            oz: "Сенатор",
            ru: "Сенатор",
            en: "Сенатор",
          },
        },
        {
          value: 2,
          name: {
            uz: "Devon",
            oz: "Девон",
            ru: "Девон",
            en: "Девон",
          },
        },
      ],
    };
  },
  computed: {
    getKey() {
      return this.langTab;
    },
    isFullNameNull() {
      return !!(
        !this.form.name.uz &&
        !this.form.name.oz &&
        !this.form.name.ru &&
        !this.form.name.en
      );
    },
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click();
    },
    openStoreModal() {
      this.modalVisible = true;
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab == "uz" ? "oz" : "uz",
          methodName = this.langTab == "uz" ? "toKirill" : "toLatin";

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from]);
        }
        this.form.language_support[to] = true;
      } catch (error) {
        this.$sentry.captureMessage(error);
      }
    },
    async submitStaffs() {
      if (this.pending) return;

      this.pending = true;
      try {
        let newData = cloneDeep(this.form);

        newData = {
          ...newData,
        };
        delete newData.birth_region_id;
        let f = new FormData();
        Object.keys(newData).forEach((key) => {
          key == "name" && f.append(key, JSON.stringify(this.form[key]));
          key == "nationality" && f.append(key, JSON.stringify(this.form[key]));
          key == "degree" && f.append(key, JSON.stringify(this.form[key]));
          key == "biography" && f.append(key, JSON.stringify(this.form[key]));
          key == "work_place" && f.append(key, JSON.stringify(this.form[key]));
          key == "socials" && f.append(key, JSON.stringify(this.form[key]));
          key == "awards" && f.append(key, JSON.stringify(this.form[key]));
          key == "birth_address" &&
            f.append(key, JSON.stringify(this.form[key]));
          key == "profession" && f.append(key, JSON.stringify(this.form[key]));
          delete newData.name;
          delete newData.nationality;
          delete newData.degree;
          delete newData.biography;
          delete newData.work_place;
          delete newData.socials;
          delete newData.awards;
          delete newData.profession;
          delete newData.birth_address;
          key != "avatar" && newData[key] && f.append(key, newData[key]);
          key == "status" && f.append(key, newData[key]);
          key == "avatar" && f.append("avatar[]", newData[key]);
        });
        let { data } = await this.$api.post("/admin/person/create", f);
        if (data.status) {
          this.$router.go(-1);
        }
        this.pending = false;
      } catch (error) {
        console.error(error);
        this.$sentry.captureMessage(error);
      }
    },
    handleFile(e) {
      this.avatar = URL.createObjectURL(e.target.files[0]);
      this.form.avatar = e.target.files[0];
    },
    async onChangeRegionBirth(val) {
      try {
        let { data } = await this.$api.get("/admin/catalog/list", {
          params: { category: "district", parent_id: val },
        });
        if (data.status) {
          this.districtListBirth = (data.data && data.data.results) || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onChangeRegionLiving(val) {
      try {
        let { data } = await this.$api.get("/admin/catalog/list", {
          params: { category: "district", parent_id: val },
        });
        if (data.status) {
          this.districtListLiving = (data.data && data.data.results) || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    try {
      let { data } = await this.$api.get("/admin/catalog/list", {
        params: { category: "region" },
      });
      if (data.status) {
        this.regionList = (data.data && data.data.results) || [];
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
.ant-modal {
  max-height: 600px;
}

.scroll-container {
  padding: 20px 0px;
}

.form_image > .deputy_image {
  width: 70px;
  height: 70px;
  border: 1px solid grey;
  border-radius: 40px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}

.form_image > .deputy_image > img {
  width: 100%;
  height: 100%;
}

.form_image {
  margin: 10px 0;
  height: 70px;
  display: flex;
  align-items: center;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
