<template>
  <a-row class="content-fix" type="flex">
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab == 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />{{ $t("ingradient") }}
        </button>
        <button
          v-if="form.type == 1"
          class="tab-btn"
          :class="{ active: tab == 'socials' }"
          @click="tab = 'socials'"
        >
          <a-icon type="link" />{{ $t("SocialNetwork") }}
        </button>
      </a-row>
      <div v-show="tab == 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
          <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <!-- <a-button v-if="langTab == 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab == "uz" ? "кирилицу" : "латиницу" }}
              </a-button> -->
            </a-row>
          </a-col>
        </a-row>
        <!-- title -->
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="sm-mb" />
          <a-col :span="12" class="sm-mb">
            <div class="form_field">
              <div class="deputy_image">
                <img :src="avatar" />
              </div>
              <a-button style="font-size: 13px" type="primary" @click="uploadFile">
                <a-icon style="font-size: 16px" type="upload" />{{
                  $t("UploadImage")
                }}</a-button
              >
              <span v-if="!avatar" style="padding-left: 8px">Картинка не выбрана</span>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFile"
              />
            </div>
          </a-col>
        </a-row>
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="form_title">
            <label>{{ $t("FullName") }}</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.name[langTab]"
              class="form_title-input"
              :placeholder="$t('FormFirstNamePlace')"
            />
          </a-col>
        </a-row>
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="form_title">
            <label>{{ $t("Position") }}</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input
              v-model="form.work_place[langTab]"
              class="form_title-input"
              :placeholder="$t('PositionPlace')"
            />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>{{ $t("EmployeeType") }}</label>
          </a-col>
          <a-col :span="4" class="sm-mb">
            <a-select
              v-model="form.type"
              :placeholder="$t('Choose')"
              style="width: 100%; padding-right: 8px"
            >
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name && item.name[$i18n.locale] }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <div v-if="form.type == 1">
          <a-row
            :class="{ 'form-group--error': emptyTitle }"
            align="middle"
            class="flex-baseline"
          >
            <a-col :span="4" class="form_title">
              <label>{{ $t("FormBirthDate") }}</label>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                v-model="form.birth_date"
                type="date"
                class="inputNumber"
                :placeholder="$t('FormBirthDate')"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("FormBirthPlace") }}</label>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-select
                allow-clear
                v-model="form.birth_region_id"
                :placeholder="$t('Select')"
                style="width: 100%; padding-right: 8px"
                @change="onChangeRegionBirthSelect"
              >
                <template #clearIcon>
                  <a-icon type="close" />
                </template>
                <a-select-option
                  v-for="item in regionList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-select
                allow-clear
                v-model="form.birth_district_id"
                :placeholder="$t('Select')"
                style="width: 100%"
              >
                <template #clearIcon>
                  <a-icon type="close" />
                </template>
                <a-select-option
                  v-for="item in districtListBirth"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                style="margin-left: 10px"
                v-model="form.birth_address[langTab]"
                placeholder="Адрес"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("Region") }}</label>
            </a-col>
            <a-col :span="4" class="sm-mb">
              <a-select
                allow-clear
                v-model="form.living_region_id"
                :placeholder="$t('Select')"
                style="width: 100%; padding-right: 8px"
                >
                <!-- @change="onChangeRegionLiving" -->
                <template #clearIcon>
                  <a-icon type="close" />
                </template>
                <a-select-option
                  v-for="item in regionList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row
            :class="{ 'form-group--error': emptyTitle }"
            align="middle"
            class="flex-baseline"
          >
            <a-col :span="4" class="form_title">
              <label>{{ $t("FormNat") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form.nationality[langTab]" :placeholder="$t('FormNat')" />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("Degree") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form.degree[langTab]" :placeholder="$t('Degree')" />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("Specialty") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.profession[langTab]"
                :placeholder="$t('Specialty')"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title">
              <label>{{ $t("FormState") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form.awards[langTab]" :placeholder="$t('FormState')" />
            </a-col>
          </a-row>
          <a-row
            :class="{ 'form-group--error': emptyTitle }"
            align="middle"
            class="flex-baseline"
          >
            <a-col :span="4" class="form_title">
              <label>{{ $t("Email") }}</label>
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input v-model="form.email" required :placeholder="$t('EnterEmail')" />
            </a-col>
          </a-row>
        </div>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title"> {{ $t("MenuPosition") }} </a-col>
          <a-col :span="2" class="sm-mb">
            <a-input
              v-model="form.position"
              type="number"
              name="position"
              :placeholder="$t('PositionEnter')"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 15px">
          <a-col :span="4" class="form_title"> {{ $t("Status") }}: </a-col>
          <a-switch v-model="form.status" />
        </a-row>
        <!-- editor -->
        <a-row style="margin-top: 25px" type="flex" v-if="form.type == 1">
          <a-col :span="4" class="form_title"> {{ $t("Biography") }}: </a-col>
          <a-col v-show="langTab == 'oz'" :span="18">
            <editor
              :value="form.biography.oz"
              :initial-value="form.biography.oz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.oz = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'uz'" :span="18">
            <editor
              :value="form.biography.uz"
              :initial-value="form.biography.uz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.uz = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'ru'" :span="18">
            <editor
              :value="form.biography.ru"
              :initial-value="form.biography.ru"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.ru = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'qr'" :span="18">
            <editor
              :value="form.biography.qr"
              :initial-value="form.biography.qr"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.qr = editorChangeHandler(arguments[0])"
            />
          </a-col>
          <a-col v-show="langTab == 'en'" :span="18">
            <editor
              :value="form.biography.en"
              :initial-value="form.biography.en"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.biography.en = editorChangeHandler(arguments[0])"
            />
          </a-col>
        </a-row>
        <a-row class="form-buttons__action" style="margin-top: 20px" type="flex">
          <a-col :span="4" class="form_title" />
          <a-col>
            <a-button
              :disabled="pending"
              @click="
                $router.push({
                  name: 'senators',
                })
              "
            >
              <a-icon type="close" />{{ $t("Cancel") }}
            </a-button>

            <a-button
              :loading="pending"
              style="margin-left: 10px"
              type="primary"
              @click="updateStaffs((saveback = false))"
            >
              <a-icon type="check" />
              {{ $t("Save") }}
            </a-button>
            <a-button
              :loading="pending"
              style="margin-left: 10px"
              type="primary"
              @click="updateStaffs((saveback = true))"
            >
              <a-icon type="check" />
              {{ $t("SaveAndExit") }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div v-show="tab == 'socials'">
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Telegram</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.telegram" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Instagram</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.instagram" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Facebook</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.facebook" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">
            <label>Twitter</label>
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-input v-model="form.socials.twitter" :placeholder="$t('Placeholder')" />
          </a-col>
        </a-row>
      </div>
    </a-col>
    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import spoiler from "@/mixins/spoiler.js";
const newsDefaultFields = () => ({
  name: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  nationality: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  socials: {
    telegram: "",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  degree: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  profession: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  biography: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  work_place: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: "",
  },
  awards: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  birth_address: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  email: "",
  phone_number: "",
  inner_number: "",
  birth_date: "",
  birth_region_id: "",
  birth_district_id: "",
  living_region_id: "",
  status: true,
  avatar: null,
  position: null,
  type: null,
});
export default {
  name: "StaffsDetail",
  components: {
    spoilerModal: () => import("@/components/directory/modal-components/modal-spoiler"),
    editor: () => import("@tinymce/tinymce-vue"),
  },
  mixins: [spoiler],
  data() {
    return {
      langTab: $langPrefix,
      form: { ...newsDefaultFields() },
      pending: false,
      loading: false,
      emptyTitle: false,
      modalVisible: false,
      avatar: null,
      regionList: [],
      districtListBirth: [],
      districtListLiving: [],
      tab: "content",
      typeList: [
        {
          value: 1,
          name: {
            uz: "Senator",
            oz: "Сенатор",
            ru: "Сенатор",
            en: "Сенатор",
          },
        },
        {
          value: 2,
          name: {
            uz: "Devon",
            oz: "Девон",
            ru: "Девон",
            en: "Девон",
          },
        },
      ],
    };
  },
  computed: {
    // isTitleNull() {
    //   return !!(
    //     !this.form.title.uz &&
    //     !this.form.title.oz &&
    //     !this.form.title.ru &&
    //     !this.form.title.en
    //   )
    // },
    getKey() {
      return this.langTab;
    },
  },
  watch: {
    "$route.params.id": {
      handler(newVal, oldVal) {
        if (+oldVal != +newVal) this.fetchData();
      },
      immediate: true,
    },
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click();
    },
    openStoreModal() {
      this.modalVisible = true;
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab == "uz" ? "oz" : "uz",
          methodName = this.langTab == "uz" ? "toKirill" : "toLatin";

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from]);
        }

        if (this.form.content[from]) {
          this.form.content[to] = this[methodName](this.form.content[from]);
        }
        this.form.language_support[to] = true;
      } catch (error) {
        this.$sentry.captureMessage(error);
      }
    },
    async updateStaffs(saveback) {
      if (this.pending) return;
      this.pending = true;
      try {
        this.form.nationality_id = 221;
        let newData = cloneDeep(this.form);
        newData = {
          ...newData,
        };
        // delete newData.birth_district_id;
        if (typeof newData.avatar == "string") {
          delete newData.avatar;
        }
        // console.log(typeof newData.avatar == "string")
        let f = new FormData();
        newData.avatar && f.append("avatar[]", newData.avatar);
        Object.keys(newData).forEach((key) => {
          key == "name" && f.append(key, JSON.stringify(this.form[key]));
          key == "nationality" && f.append(key, JSON.stringify(this.form[key]));
          key == "degree" && f.append(key, JSON.stringify(this.form[key]));
          key == "biography" && f.append(key, JSON.stringify(this.form[key]));
          key == "work_place" && f.append(key, JSON.stringify(this.form[key]));
          key == "socials" && f.append(key, JSON.stringify(this.form[key]));
          key == "awards" && f.append(key, JSON.stringify(this.form[key]));
          key == "profession" && f.append(key, JSON.stringify(this.form[key]));
          key == "birth_address" && f.append(key, JSON.stringify(this.form[key]));
          key == "birth_district_id" && f.append(key, this.form[key] || 0);
          key == "birth_region_id" && f.append(key, this.form[key] || 0);
          key == "living_region_id" && f.append(key, this.form[key] || 0);
          delete newData.name;
          delete newData.nationality;
          delete newData.degree;
          delete newData.biography;
          delete newData.work_place;
          delete newData.socials;
          delete newData.awards;
          delete newData.profession;
          delete newData.birth_address;
          delete newData.birth_district_id;
          delete newData.birth_region_id;
          delete newData.living_region_id;
          newData[key] && key != "avatar" && f.append(key, newData[key]);
          key == "status" && f.append(key, newData[key]);
        });
        let { data } = await this.$api.put(`/admin/person/${this.form.id}`, f);
        if (data.status && saveback) {
          this.$router.go(-1);
        }
        this.pending = false;
      } catch (error) {
        console.error(error);
        this.$sentry.captureMessage(error);
      }
    },
    fetchData() {
      this.loading = true;
      if (isNaN(this.$route.params.id)) {
        this.loading = false;
        return;
      }
      this.$api
        .get(`/admin/person/${this.$route.params.id}`)
        .then(async (res) => {
          if (res && res.data) {
            const { ...data } = res.data;
            this.onChangeRegionBirth(data.data && data.data.birth_region_id);
            // this.onChangeRegionLiving(data.data && data.data.living_region_id);
            this.form = {
              ...this.form,
              ...data.data,
            };
            // console.log(this.form);
            this.avatar = this.form.avatar;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleFile(e) {
      this.avatar = URL.createObjectURL(e.target.files[0]);
      this.form.avatar = e.target.files[0];
    },
    async onChangeRegionBirth(val) {
      try {
        let { data } = val && await this.$api.get("/admin/catalog/list", {
          params: { category: "district", parent_id: val },
        });
        if (data.status) {
          this.districtListBirth = (data.data && data.data.results) || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onChangeRegionBirthSelect(val) {
      try {
        let { data } = val && await this.$api.get("/admin/catalog/list", {
          params: { category: "district", parent_id: val },
        });
        if (data.status) {
          this.form.birth_district_id = null
          this.districtListBirth = (data.data && data.data.results) || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    // async onChangeRegionLiving(val) {
    //   try {
    //     let { data } = val && await this.$api.get("/admin/catalog/list", {
    //       params: { category: "district", parent_id: val },
    //     });
    //     if (data.status) {
    //       this.districtListLiving = (data.data && data.data.results) || [];
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
  async created() {
    try {
      let { data } = await this.$api.get("/admin/catalog/list", {
        params: { category: "region" },
      });
      if (data.status) {
        this.regionList = (data.data && data.data.results) || [];
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
.ant-modal {
  max-height: 600px;
}

.scroll-container {
  padding: 20px 0px;
}

.form_field > .deputy_image {
  width: 75px;
  height: 100px;
  border: 1px solid grey;
  border-radius: 4px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}

.form_field > .deputy_image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form_field {
  margin: 10px 0;
  height: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
